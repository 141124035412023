<template>
  <div
    class="grid"
    :class="[
      hideMobile ? 'm--hide' : 'm--show',
      mobilePadding ? mobilePadding : '',
      mobileMargin ? mobileMargin : '',
      mobileColumns ? 'mc--' + mobileColumns : '',
      mobileGutter ? 'mg--' + mobileGutter : '',
      hideDesktop ? 'd--hide' : 'd--show',
      desktopPadding ? desktopPadding : '',
      desktopMargin ? desktopMargin : '',
      desktopColumns ? 'dc--' + desktopColumns: '',
      desktopGutter ? 'dg--' + desktopGutter : '',
      desktopIndent ? 'di ' + desktopIndent : '',
      backgroundColor ? 'bg-color--' + backgroundColor : '',
    ]"
    :style="[
      backgroundColorCss,
      backgroundImageCss
    ]"
  >
    <slot />
    <Headline
      v-for="(headline, index) in headlines"
      :key="'headline' + index"
      :headline-type="headline.headlineType"
      :headline-size="headline.headlineSize"
      :headline-color="headline.headlineColor"
      :headline-accent-color="headline.headlineAccentColor"
      :eyebrow="headline.eyebrow"
      :headline="headline.headline"
      :lead="headline.lead"
      :align-headline="headline.alignHeadline"
      :vertical-margin="headline.verticalMargin"
    />
    <template
      v-for="block in content"
    >
      <div
        v-if="block.component === 'Button'"
        :key="block.id"
        class="alignment"
      >
        <Button
          v-editable="block"
          v-bind="block"
        />
      </div>
      <component
        :is="block.component"
        v-else
        :key="block.id"
        v-editable="block"
        v-bind="block"
        :mobile-display-size="mobileDisplaySize"
        :desktop-display-size="desktopDisplaySize"
      />
    </template>
  </div>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

export default {
  name: 'Grid',
  props: {
    hideMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileMargin: {
      type: String,
      required: false,
      default: 'mm--none'
    },
    mobilePadding: {
      type: String,
      required: false,
      default: 'mp--none'
    },
    mobileColumns: {
      type: String,
      required: false,
      default: '1'
    },
    mobileGutter: {
      type: String,
      required: false,
      default: 'none'
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopMargin: {
      type: String,
      required: false,
      default: 'dm--none'
    },
    desktopPadding: {
      type: String,
      required: false,
      default: 'dp--none'
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '1'
    },
    desktopGutter: {
      type: String,
      required: false,
      default: 'none'
    },
    desktopIndent: {
      type: String,
      required: false,
      default: 'di--none'
    },
    headlines: {
      type: Array,
      required: false,
      default () {}
    },
    content: {
      type: Array,
      required: false,
      default () {}
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white'
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImage: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    /**
     * Depending on the number of columns, we pass the 'mobileDisplaySize' and 'desktopDisplaySize' as optional
     * props into the child component where we can apply them on images to get optimized image sizes
     */
    mobileDisplaySize () {
      return parseInt(100 / this.mobileColumns) + 1 + 'vw'
    },
    desktopDisplaySize () {
      return parseInt(100 / this.desktopColumns) + 1 + 'vw'
    },

    backgroundImageCss () {
      return this.backgroundImage && this.backgroundImage.filename
        ? { backgroundImage: `url(${this.$imgproxy.transform(this.backgroundImage.filename, 'preset:sharp/resize:fit:1500/gravity:nowe/q:70')})` }
        : ''
    },
    backgroundColorCss () {
      const backgroundColor = color(this.backgroundColor, this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.grid {
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  box-sizing: border-box;
  &::v-deep {
    .headline {
      grid-column: 1/-1;
    }
  }
  .alignment {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  &.m--show {
    display: grid !important;
  }
  &.m--hide {
    display: none !important;
  }
  .image-block {
    width: 100%;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .grid {
    &.d--show {
      display: grid !important;
    }
    &.d--hide {
      display: none !important;
    }
    /*
     * The SwipeableProductList should not be used in this grid due to potential bugs on larger screens caused by
     * vue-slick-carousel. There is a use case for mobile usage though, why we do like this.
     */
    .product-list-swipeable {
      display: none;
    }
  }
}
</style>
