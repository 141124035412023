var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid",class:[
    _vm.hideMobile ? 'm--hide' : 'm--show',
    _vm.mobilePadding ? _vm.mobilePadding : '',
    _vm.mobileMargin ? _vm.mobileMargin : '',
    _vm.mobileColumns ? 'mc--' + _vm.mobileColumns : '',
    _vm.mobileGutter ? 'mg--' + _vm.mobileGutter : '',
    _vm.hideDesktop ? 'd--hide' : 'd--show',
    _vm.desktopPadding ? _vm.desktopPadding : '',
    _vm.desktopMargin ? _vm.desktopMargin : '',
    _vm.desktopColumns ? 'dc--' + _vm.desktopColumns: '',
    _vm.desktopGutter ? 'dg--' + _vm.desktopGutter : '',
    _vm.desktopIndent ? 'di ' + _vm.desktopIndent : '',
    _vm.backgroundColor ? 'bg-color--' + _vm.backgroundColor : '',
  ],style:([
    _vm.backgroundColorCss,
    _vm.backgroundImageCss
  ])},[_vm._t("default"),_vm._v(" "),_vm._l((_vm.headlines),function(headline,index){return _c('Headline',{key:'headline' + index,attrs:{"headline-type":headline.headlineType,"headline-size":headline.headlineSize,"headline-color":headline.headlineColor,"headline-accent-color":headline.headlineAccentColor,"eyebrow":headline.eyebrow,"headline":headline.headline,"lead":headline.lead,"align-headline":headline.alignHeadline,"vertical-margin":headline.verticalMargin}})}),_vm._v(" "),_vm._l((_vm.content),function(block){return [(block.component === 'Button')?_c('div',{key:block.id,staticClass:"alignment"},[_c('Button',_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}]},'Button',block,false))],1):_c(block.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}],key:block.id,tag:"component",attrs:{"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize}},'component',block,false))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }